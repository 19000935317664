<div class="transport-plan-container">
  <div class="left-panel">
    <div class="tp-title">
      <p>Transport Plan</p>
    </div>
    <div class="header">
      <div class="container-details">
        <p class="container-number">{{selectedContainer?.containerNumber ? selectedContainer?.containerNumber : selectedContainer?.containerId}}</p>
        <p class="container-type">{{selectedContainer?.containerTypeDescription}}</p>
      </div>
      <div class="full-screen">
        <button mat-button color="primary">FULL SCREEN <span class="icon"></span></button>
      </div>
      <div class="view-activities">
        <button mat-raised-button color="primary">View All Activities</button>
      </div>
    </div>
    <div class="map">
      <hmt-map #mapComponent (selectLocation)="onChangeLocation($event)"></hmt-map>
    </div>
    <div class="location arrow-top">
      <div class="left-class">
        <div class="row-1">
          <div class="location-title">{{selectedLocation?.name}}</div>
          <div class="billing-info" *ngIf="selectedService?.billingActivated">
            <div class="billing-label">
              <div class="billing-icon-size">
                <mat-icon>attach_money</mat-icon>
              </div>
              <div class="billing-indicator">
                {{selectedService?.billingIndicatorType === 'BILLING_START' ? 'Billing Start' : 'Billing End'}}
              </div>
            </div>
          </div>
        </div>
        <div class="location-details">
          <div class="company-details">
            <div>
              <P class="font-14">Location Type : {{selectedLocation?.locationType}}</P>
              <P class="font-14">Activity Type : {{selectedService?.activityId}}</P>
            </div>
            <div>
              <P class="font-11">Company</P>
              <P class="font-14">{{selectedService?.orgDetails?.orgName}}</P>
            </div>
            <div>
              <P class="font-11">Contact Person</P>
              <P class="font-14">{{selectedLocation?.principalContact?.firstName}} {{selectedLocation?.principalContact?.lastName}}</P>
            </div>
          </div>
          <div class="contact-details">
            <div>
              <P class="font-12"><b>Phone</b> : {{selectedLocation?.principalContact?.mobile}}</P>
              <P class="font-12"><b>Fax</b> : {{selectedLocation?.principalContact?.faxNumber}}</P>
            </div>
            <div class="company-contact">
              <P class="font-12"><b>Phone</b> : {{selectedService?.orgDetails?.principalContact?.mobile}}</P>
              <P class="font-12"><b>Fax</b> : {{selectedService?.orgDetails?.principalContact?.faxNumber}}</P>
            </div>
          </div>
        </div>
      </div>
      <div class="time-details">
        <div>
          <P class="font-11">Requested Time of Arrival</P>
          <P class="font-14">{{selectedService?.requestedTimeOfArrival |date:'yyyy-MM-dd hh:mm:a' }}</P>
        </div>
        <div>
          <P class="font-11">Requested time of Leaving</P>
          <P class="font-14">{{selectedService?.requestedTimeOfLeave | date:'yyyy-MM-dd hh:mm:a'}}</P>
        </div>
        <div>
          <P class="font-11">Estimated Duration</P>
          <P class="font-14">{{selectedService?.estimatedDuration}} min</P>
        </div>
      </div>
    </div>
    <div class="class-e">
      <div>Select <br> Location</div>
      <ng-container *ngFor="let locationLabel of locationLabels">
        <button
          [ngClass]="{'container-box-focus': locationLabel.isActive ? true: false, 'container-box': locationLabel.isActive ? false: true}"
          id="{{locationLabel}}" mat-button
          (click)="onChangeLocation(locationLabel.label)">{{locationLabel.label}}</button>
      </ng-container>
    </div>
    <div class="c-details" *ngIf="selectedContainer?.orderRefNumbers?.length > 0">
      <p>Order Reference Number(s)</p>
      <div *ngFor="let refNumber of selectedContainer.orderRefNumbers">
        <div class="refNumber">{{refNumber}}</div>
      </div>
    </div>
    <div class="c-details" *ngIf="selectedContainer?.otherRefNumbers?.length > 0">
      <p>Other Reference Number(s)</p>
      <div *ngFor="let refNumber of selectedContainer.otherRefNumbers">
        <div class="refNumber">{{refNumber}}</div>
      </div>
    </div>
  </div>
  <div class="right-panel">
    <div class="containers-list-item">
      <form>
        <!--TODO: implement search -->
        <input class="input-field" type="text" placeholder="Search for Vehicals, People, Jobs ...">
      </form>
    </div>
    <ng-container *ngFor="let container of data?.containers; let i = index">
      <div class="containers-list-item" tabindex="{{i}}" (click)="changeContainer(container)">
        <div class="font-18">{{container.containerNumber ? container.containerNumber : container.containerId}}</div>
        <div class="font-14-ash">{{container.containerTypeDescription}}</div>
        <div class="font-11-ash"><b>{{container.commodity}} </b></div>
        <div class="font-11-ash" >
          <b>HS Codes</b>
          <div *ngFor="let hsCode of container.hsCodes">{{hsCode}}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

