import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '@configs/api.config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Pageable} from '@shared/models/pageable.model';
import {JobFile} from '@core/models/job-file';
import {ShipmentOrderSpec} from '@core/models/job-file/shipment-order-spec.model';
import {ShipmentOrderSpecFilterRequest} from '../models/shipment-order-spec-filter-request.model';
import { FileDetails } from '../models/state/file-details.model';
import { PreSignedUrl } from '@control-tower/models/jpm/pre-signed-url';

@Injectable({
  providedIn: 'root'
})
export class OrderManagementService {

  constructor(private httpClient: HttpClient) { }

  loadShipmentOrderSpecs(orgId: string, pageNo: number, pageSize: number, searchKey): Observable<Pageable<ShipmentOrderSpec>> {
    let params = new HttpParams();
    params = params.set('searchKey', searchKey);
    const url = API.jobFile.loadShipmentOrderSpecs.format([orgId, pageNo.toString(), pageSize.toString()]);
    return this.httpClient.get<Pageable<ShipmentOrderSpec>>(url, {params});
  }

  uploadShipmentOrderExcel(file: File, orgId: string): Observable<ShipmentOrderSpec[]> {
    const data = new FormData();
    data.append('file', file, file.name);
    const url = API.jobFile.uploadShipmentOrderExcel.format([orgId]);
    return this.httpClient.post<ShipmentOrderSpec[]>(url, data);
  }

  addShipmentOrdersToJob(orgId: string, jobRefId: string, shipmentOrderSpecs: ShipmentOrderSpec[]): Observable<JobFile> {
    const url = API.jobFile.addShipmentOrdersToJob.format([orgId, jobRefId]);
    return this.httpClient.put<JobFile>(url, shipmentOrderSpecs);
  }

  deleteShipmentOrder(shipmentOrderId: string) {
    const url = API.jobFile.deleteShipmentOrder.format([shipmentOrderId]);
    return this.httpClient.delete(url);
  }

  filterShipmentOrderSpecs(shipmentOrderSpecFilterRequest: ShipmentOrderSpecFilterRequest): Observable<Pageable<ShipmentOrderSpec>> {
    const url = API.jobFile.filterShipmentOrderSpecs;
    return this.httpClient.post<Pageable<ShipmentOrderSpec>>(url, shipmentOrderSpecFilterRequest);
  }

  deleteAllShipmentOrderSpecs(orgId: string): Observable<void> {
    const url = API.jobFile.deleteAllShipmentOrderSpecs.format([orgId]);
    return this.httpClient.delete<void>(url);
  }

  uploadOrderDocument(orgId: string, orderId: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const url = API.jobFile.uploadOrderDocument.format([orgId, orderId]);
    return this.httpClient.post<any>(url, formData);
  }

  getFilesByOrgAndOrder(orgId: string, orderId: string): Observable<FileDetails[]> {
    const url = API.fileHandelar.getFilesByOrgAndOrder.format([orgId, orderId]);
    return this.httpClient.get<FileDetails[]>(url);
  }

  deleteFile(fileId: string): Observable<void> {
    const url = API.fileHandelar.deleteFileMetadata.format([fileId]);
    return this.httpClient.delete<void>(url);
  }

  viewFile(fileId: string): Observable<FileDetails> {
    const url = API.fileHandelar.getPreSignedUrl.format([fileId]);
    return this.httpClient.get<PreSignedUrl>(url);
  }
}
