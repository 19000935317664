import { Injectable } from '@angular/core';
import {PaymentType} from '../models/enums/payment-type.enum';
import {ReadyToInvoice} from '../models/ready-to-invoice';
import {Expense} from '../models/expense.model';
import {FeeType} from '@shared/models/enums/commission-fee.type';

@Injectable({
  providedIn: 'root'
})
export class RtiExtraServiceService {

  constructor() { }

  calculateSubTotal(readyToInvoice: ReadyToInvoice) {
    const serviceFee = this.calculateServiceFee(readyToInvoice);

    const lineItemsTotal = readyToInvoice?.addedOtherCost.reduce((total, cost) => {
      const amount = cost.paymentType === PaymentType.PERCENTAGE
        ? parseFloat((serviceFee / 100 * cost.amount).toFixed(2))
        : cost.amount;
      return total + amount;
    }, 0);

    const addedDiscount = readyToInvoice?.addedDiscount;
    const calculatedDiscount = addedDiscount
      ? (addedDiscount.paymentType === PaymentType.PERCENTAGE
        ? parseFloat(((serviceFee / 100) * addedDiscount.amount).toFixed(2))
        : addedDiscount.amount)
      : 0;

    const serviceFeePlusLineItems = serviceFee + lineItemsTotal;
    return serviceFeePlusLineItems - calculatedDiscount;
  }

  calculateServiceFee(readyToInvoice: ReadyToInvoice) {
    const loadFee = this.calculateTotalOfAllLoads(readyToInvoice);
    const additionalCostTotal = readyToInvoice.addedAdditionalCost.reduce((totalCost, cost) => totalCost + cost.amount, 0);
    return loadFee+additionalCostTotal;
  }

  calculateTotalOfAllLoads(readyToInvoice: ReadyToInvoice) {
    let commissionTotal = 0;
    readyToInvoice.commissions.forEach(commission => {
      commission.feeType.forEach(feeType => {
        commissionTotal += this.calculateCommissions(commission, readyToInvoice, feeType);
      });
    });
    return readyToInvoice?.loadCost
      + readyToInvoice.expenses.reduce((totalCost, cost) => totalCost + cost.amount, 0) + commissionTotal;
  }

  calculateCommissions(commission: Expense, readyToInvoice: ReadyToInvoice, feeType: string) {
    if(feeType === FeeType.TRANSPORTATION_FEES  ) {
      const total = commission.amount * readyToInvoice.loadCost / 100;
      return parseFloat(total.toFixed(2));
    }
    if(feeType === FeeType.EXTRA_KILOMETER_CHARGE) {
      let total = 0;
      readyToInvoice.expenses.forEach(expense => {
        if(expense.feeType?.includes(FeeType.EXTRA_KILOMETER_CHARGE)) {
          total += expense.amount;
        }
      });
      const totalCommission = commission.amount * total / 100;
      return parseFloat(totalCommission.toFixed(2));
    }
    if(feeType === FeeType.DEMURRAGE) {
      let total = 0;
      readyToInvoice.expenses.forEach(expense => {
        if(expense.feeType?.includes(FeeType.DEMURRAGE)) {
          total += expense.amount;
        }
      });
      const totalCommission = commission.amount * total / 100;
      return parseFloat(totalCommission.toFixed(2));
    }
    if(feeType === FeeType.FIXED_COSTS) {
      let total = 0;
      readyToInvoice.expenses.forEach(expense => {
        if(expense.feeType?.includes(FeeType.FIXED_COSTS)) {
          total += expense.amount;
        }
      });
      const totalCommission = commission.amount * total / 100;
      return parseFloat(totalCommission.toFixed(2));
    }
    if(feeType === FeeType.ADDITIONAL_EXPENSES) {
      let total = 0;
      readyToInvoice.expenses.forEach(expense => {
        if(expense.feeType?.includes(FeeType.ADDITIONAL_EXPENSES)) {
          total += expense.amount;
        }
      });
      const totalCommission = commission.amount * total / 100;
      return parseFloat(totalCommission.toFixed(2));
    }
    return 0;
  }
}
