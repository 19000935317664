import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {InitContainerViewModel} from '../../models/init-container-view.model';

@Component({
  selector: 'hmt-information-table',
  templateUrl: './information-table.component.html',
  styleUrls: ['./information-table.component.scss']
})
export class InformationTableComponent implements OnInit {
  @Input() data: { headers: { title: string, columnNames: string[], tableHeaders: string[] }, containers: InitContainerViewModel[] };
  columnNames: string[] = [];
  header: string;
  tableHeaders = [];
  tableDataList = [];
  dataSource: MatTableDataSource<{ [key: string]: string | number }>;

  constructor() {
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.header = this.data.headers.title;
    this.columnNames = this.data.headers.columnNames;
    this.tableHeaders = this.data.headers.tableHeaders;
    this.tableDataList = this.data.containers.map(container => {
      const newContainer = {
        referenceNumbers: undefined,
        ...container
      };
      newContainer.containerId = container.containerNumber ? container.containerNumber : container.containerId;
      const orderRefNumbers = container?.orderRefNumbers ?? [];
      const otherRefNumbers = container?.otherRefNumbers ?? [];
      newContainer.referenceNumbers = [...orderRefNumbers, ...otherRefNumbers];
      return newContainer;
    });
    this.dataSource = new MatTableDataSource(this.tableDataList);
  }
}
