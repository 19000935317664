<div class="shipment-order col">
  <form class="col" [formGroup]="formGroup">
    <section formGroupName="shipmentOrderAssignmentData" class="row order-options">
      <div class="select-checkbox" *ngIf="selectable">
        <mat-checkbox class="select-shipment-order" (change)="onSelectOrder($event, order)"></mat-checkbox>
      </div>
      <div class="col title">
        <span>Order ID</span>
        <span class="order-id">{{order?.orderId}}</span>
      </div>
      <div class="fill-space"></div>

      <div [formGroupName]="order.orderId" class="row consignee">
        <span>Consignee</span>
        <mat-form-field class="full-width" appearance="outline">
          <input type="text" matInput formControlName="consignee" placeholder="Consignee">
        </mat-form-field>
        <!-- <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select Consignee</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <mat-icon matSuffix>arrow_drop_down</mat-icon>
          <input type="text" matInput formControlName="consignee" placeholder="Search the Job Consignee"
            [matAutocomplete]="consigneeAutoComplete">
          <mat-autocomplete #consigneeAutoComplete="matAutocomplete" [displayWith]="displayWithConsigneeName">
            <mat-option class="select-label" *ngFor="let consignee of consignees" [value]="consignee">
              {{consignee?.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field> -->
      </div>
      <div [formGroupName]="order.orderId" class="row priority">
        <span>Priority</span>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select Priority</mat-label>
          <mat-icon matSuffix>arrow_drop_down</mat-icon>
          <input type="text" matInput formControlName="priority" placeholder="Search the Job Consignee"
            [matAutocomplete]="priorityAutoComplete">
          <mat-autocomplete #priorityAutoComplete="matAutocomplete">
            <mat-option class="select-label" *ngFor="let priority of priorities" [value]="priority">
              {{priority}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="row va-c ha-c" *ngIf="deletable">
        <button class="delete-btn" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </section>

    <section class="row ha-c va-c order-summary">
      <div class="col pickup-at">
        <label>Pickup at</label>
        <span class="">{{order?.pickupLocationName}}</span>
      </div>
      <mat-icon>arrow_forward</mat-icon>
      <div class="col delivery">
        <label>Delivery</label>
        <span class="">{{order?.dropOffLocationName}}</span>
      </div>
      <div class="col total-volume">
        <label>Total Volume</label>
        <span class="">{{order?.totalCBM | number: '1.1-2'}} CBM</span>
      </div>
      <div class="col total-weight">
        <label>Total Weight</label>
        <span class="">{{order?.totalWeight | number: '1.1-2'}} Kg</span>
      </div>
      <div class="fill-space"></div>
      <div class="col order-from">
        <label>Order from</label>
        <span class="">logo</span>
      </div>
    </section>

    <section class="row date-summary">
      <div class="row ds-wrapper">
        <div class="col">
          <label class="text-small">Planned Pickup date and time</label>
          <label class="text-medium">{{order?.pickupTime | date: 'M/d/yyyy, h:mm a'}}</label>
        </div>
        <div class="col">
          <label class="text-small">Pickup Time Window</label>
          <label class="text-medium">{{order?.pickupTimeWindow}}</label>
        </div>
      </div>
      <div class="row ds-wrapper">
        <div class="col">
          <label class="text-small">Planned Delivery date and time</label>
          <label class="text-medium">{{order?.deliveryEndDateTime | date: 'M/d/yyyy, h:mm a'}}</label>
        </div>
        <div class="col">
          <label class="text-small">Delivery Time Window</label>
          <label class="text-medium">{{order?.deliveryTimeWindow}}</label>
        </div>
      </div>
      <div class="row ds-wrapper remaining-time">
        <div class="col">
          <label class="text-medium-white">Remaining time</label>
        </div>
        <div class="col">
          <label class="text-large">{{remainingTime}}</label>
        </div>
      </div>
    </section>
    <section class="order-list">
      <hmt-order-list [orders]="order.shipmentOrders || []"></hmt-order-list>
    </section>

    <section formGroupName="shipmentOrderAssignmentData" class="row shipment-options">
      <div [formGroupName]="order.orderId" class="col partial-shipment">
        <div class="title bold">Partial Shipment:</div>
        <mat-radio-group formControlName="partialShipmentAllowed" class="group" aria-label="Select an option">
          <mat-radio-button [value]="true">Allowed</mat-radio-button>
          <mat-radio-button [value]="false">Not Allowed</mat-radio-button>
        </mat-radio-group>
      </div>
      <div [formGroupName]="order.orderId" class="col shipment-assignment">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select Load</mat-label>
          <mat-icon matSuffix>arrow_drop_down</mat-icon>
          <input type="text" matInput formControlName="loadInfo" placeholder="Search Load"
            [matAutocomplete]="loadNumberAutoComplete">
          <mat-autocomplete #loadNumberAutoComplete="matAutocomplete" [displayWith]="displayWithOderNumber">
            <mat-option class="select-label" *ngFor="let load of loads" [value]="load">
              {{load.loadNumber}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </section>

    <section class="row attachments">
      <div class="col">
        <div class="title">Attachments</div>
        <div class="row attached-documents">
          <div class="row va-c document" *ngFor="let doc of attachments; let i = index">
              <label class="document-name" (click)="downloadFile(doc.id)">{{doc.originalFileName}}</label>
            <button class="delete-btn" mat-icon-button>
              <mat-icon  (click)="removeAttachment(doc.id, order.orderId)">delete</mat-icon>
            </button>
          </div>
        </div>
        <div class="mt-10 attach-button">
          <input
            type="file"
            #fileInput
            style="display: none"
            (change)="onFileSelected($event)"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp"
          >
          <button 
            mat-raised-button 
            class="upload uppercase"
            [disabled]="isUploading"
            (click)="fileInput.click()"
          >
            <mat-icon>{{isUploading ? 'hourglass_empty' : 'cloud_upload'}}</mat-icon>
            {{isUploading ? 'Uploading...' : 'Upload'}}
          </button>
        </div>
      </div>
      <div class="row btn-delete">
        <button mat-raised-button color="warn" (click)="deleteShipmentOrder(order)">
          <mat-icon>delete</mat-icon>Delete
        </button>
      </div>
    </section>
  </form>
</div>
