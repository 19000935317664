import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@configs/api.config";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadManifestService {

  constructor(private httpClient: HttpClient) { }

  getConsolidatedLoadManifest(orgId: string, jobRefId: string): Observable<any> {
    const url = API.report.getLoadManifests.format([orgId, jobRefId]);
    return this.httpClient.get<any>(url, {
      params:{
        type: 'CONSOLIDATED',
      }, 
      responseType: 'blob' as 'json',
    });
  }

  getLoadManifestsZip(orgId: string, jobRefId: string): Observable<any> {
    const url = API.report.getLoadManifests.format([orgId, jobRefId]);
    return this.httpClient.get<any>(url, {
      params:{
        type: 'INDIVIDUAL',
      }, 
      responseType: 'blob' as 'json',
    });
  }
}