import {Injectable} from '@angular/core';
import {RateCard} from '@shared/models/rate-card.model';
import {RateRow} from '@quotation/models/rate-row.model';
import {RateSheetType} from '@shared/models/enums/rate-sheet-type.enum';
import {InvoiceCurrency} from '../models/invoice-currency.model';
import {PaymentTerm} from '@shared/models/payment-term.model';
import {PaymentTermType} from '@shared/models/enums/payment-term-type.enum';
import {Store} from '@ngxs/store';
import {Step} from '@shared/models/step.model';
import {Invoice} from '@shared/models/invoice/invoice.model';
import {VehicleContribution} from '@shared/models/invoice/vehicle-contribution.model';
import {InvoiceLoad} from '../models/invoice-load.model';
import * as uuid from 'uuid';
import {InvoiceType} from '../models/enums/invoice-type.enum';
import {ApprovalStateType} from '../models/enums/approval-state.enum';
import {OverdueStateType} from '../models/enums/overdue-state.enum';
import {ProgressLineStepType} from '../models/enums/progress-line-step.enum';
import {TabType} from '../models/enums/invoice-state.enum';
import {Cost} from '../models/cost.model';
import {PaymentType} from '../models/enums/payment-type.enum';
import {UpdateInvoiceAction} from '../store/invoice/invoice.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {CommitmentBlock} from '@shared/models/invoice/commitment-block.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceExtraService {

  invoicePLSteps: Step[] = [
    {
      stepNo: 1,
      stepName: 'Draft',
      step: 'DRAFT'
    },
    {
      stepNo: 2,
      stepName: 'Sent for Internal Approval',
      step: 'SENT_FOR_INTERNAL_APPROVAL'
    },
    {
      stepNo: 3,
      stepName: 'Internally Approved',
      step: 'INTERNAL_APPROVED'
    },
    {
      stepNo: 4,
      stepName: 'Sent',
      step: 'SENT'
    },
    {
      stepNo: 5,
      stepName: 'Paid',
      step: 'PAID'
    },
  ];

  paymentScheduleSteps: Step[] = [
    {
      stepNo: 1,
      stepName: 'Draft',
      step: 'DRAFT'
    },
    {
      stepNo: 2,
      stepName: 'Sent for Internal Approval',
      step: 'SENT_FOR_INTERNAL_APPROVAL'
    },
    {
      stepNo: 3,
      stepName: 'Internally Approved',
      step: 'INTERNAL_APPROVED'
    },
    {
      stepNo: 4,
      stepName: 'Confirm',
      step: 'CONFIRM'
    },
    {
      stepNo: 5,
      stepName: 'Paid',
      step: 'PAID'
    },
  ];

  constructor(private store$: Store) { }

  generateRateRows(rateCard: RateCard, currency: InvoiceCurrency, vehicleCategory: string): RateRow[][] {
    let rates: RateRow[] = [];
    switch (rateCard?.type) {
      case RateSheetType.FLAG_DOWN:
        rates = this.generateFlagDownRateRows(rateCard, currency, vehicleCategory);
        break;
      case RateSheetType.TEUS:
        rates = this.generateTuesRateRows(rateCard, currency);
        break;
      case RateSheetType.LOAD_BASED:
        rates = this.generateLoadBasedRateRows(rateCard, currency);
        break;
      case RateSheetType.COMMITMENT:
        rates = this.generateCommitmentRateRows(rateCard, currency);
        break;
      default:
        rates = rateCard?.type === null ? this.generateFlagDownRateRows(rateCard, currency, vehicleCategory) : [];
    }
    return this.splitRatesIntoRows(rates);
  }

  getVehicleCategoryName(rateCard: RateCard, vehicleCategory: string){
    const flagDownRate
      = rateCard.flagDownRates[vehicleCategory] != null ? rateCard.flagDownRates[vehicleCategory] : rateCard.flagDownRates.default;
    return flagDownRate.vehicleCategoryName;
  }

  getDemurrageSlabs(rateCard: RateCard, vehicleCategory: string){
    return (rateCard.flagDownRates[vehicleCategory] != null
      ? rateCard.flagDownRates[vehicleCategory] : rateCard.flagDownRates.default).demurrageSlabs;
  }

  generateFlagDownRateRows(rateCard: RateCard, currency: InvoiceCurrency, vehicleCategory: string): RateRow[] {
    const rates: RateRow[] = [];
    const flagDownRate
      = rateCard.flagDownRates[vehicleCategory] != null ? rateCard.flagDownRates[vehicleCategory] : rateCard.flagDownRates.default;
    if (flagDownRate?.flagDownCostActivated) {
      rates['Flag Down Rate'] = flagDownRate?.flagDownCost;
      rates.push({
        name: 'Flag Down Rate',
        value: this.applyCurrency(flagDownRate?.flagDownCost, currency)
      });
    }

    if (flagDownRate?.jobFreeHoursActivated) {
      rates.push({
        name: 'Free Hours per Flag Down',
        value: flagDownRate?.jobFreeHours
      });
    }

    if (flagDownRate?.flagDownFrequencyActivated) {
      rates.push({
        name: 'Flag Down Application (Frequency)',
        value: flagDownRate?.flagDownFrequency + ' hrs'
      });
    }

    if (flagDownRate?.freeKmsPerFlagDownActivated) {
      rates.push({
        name: 'Free KM\'s per Flag Down',
        value: flagDownRate?.freeKmsPerFlagDown
      });
    }

    if (rateCard?.sellingRatePerkm) {
      rates.push({
        name: 'Rate/km',
        value: this.applyCurrency(rateCard?.sellingRatePerkm, currency)
      });
    }

    return rates;
  }

  generateTuesRateRows(rateCard: RateCard, currency: InvoiceCurrency): RateRow[] {
    const rates: RateRow[] = [];
    if (rateCard?.ratePerTeuActivated) {
      rates.push({
        name: 'Rate per TEU',
        value: this.applyCurrency(rateCard?.ratePerTeu, currency)
      });
    }

    if (rateCard?.freeHoursPerTripActivated) {
      rates.push({
        name: 'Free Hours per trip',
        value: rateCard?.freeHoursPerTrip
      });
    }

    if (rateCard?.freeKmsPerTripActivated) {
      rates.push({
        name: 'Free Kilometers per Trip',
        value: rateCard?.freeKmsPerTrip
      });
    }

    if (rateCard?.sellingRatePerExtraKmActivated) {
      rates.push({
        name: 'Rate/km',
        value: this.applyCurrency(rateCard?.sellingRatePerExtraKm, currency)
      });
    }

    return rates;
  }

  generateLoadBasedRateRows(rateCard: RateCard, currency: InvoiceCurrency): RateRow[] {
    const rates: RateRow[] = [];
    if (rateCard?.rateFor20FtActivated) {
      rates.push({
        name: '20 Ft Rate',
        value: this.applyCurrency(rateCard?.rateFor20Ft, currency)
      });
    }

    if (rateCard?.rateFor40FtActivated) {
      rates.push({
        name: '40 Ft Rate',
        value: this.applyCurrency(rateCard?.rateFor40Ft, currency)
      });
    }

    if (rateCard?.rateFor45FtActivated) {
      rates.push({
        name: '45 Ft Rate',
        value: this.applyCurrency(rateCard?.rateFor45Ft, currency)
      });
    }

    if (rateCard?.rateFor40FtReferActivated) {
      rates.push({
        name: '40 Ft Refer Rate',
        value: this.applyCurrency(rateCard?.rateFor40FtRefer, currency)
      });
    }

    if (rateCard?.freeHoursPerTripActivated) {
      rates.push({
        name: 'Free Hours per trip',
        value: rateCard?.freeHoursPerTrip
      });
    }

    if (rateCard?.freeKmsPerTripActivated) {
      rates.push({
        name: 'Free Kilometers per Trip',
        value: rateCard?.freeKmsPerTrip
      });
    }

    if (rateCard?.sellingRatePerExtraKmActivated) {
      rates.push({
        name: 'Rate/km',
        value: this.applyCurrency(rateCard?.sellingRatePerExtraKm, currency)
      });
    }

    return rates;
  }

  generateCommitmentRateRows(rateCard: RateCard, currency: InvoiceCurrency): RateRow[] {
    const rates: RateRow[] = [];
    rateCard.commitmentBlocks.forEach(block => {
      if (block?.isCommitmentExtraKmCharge) {
        rates.push({
          name: 'Extra km cost',
          value: this.applyCurrency(block?.commitmentExtraKmCharge, currency)
        });
      }
    });
    return rates;
  }


    splitRatesIntoRows(rates: RateRow[]) {
    return rates.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  applyCurrency(value: number, currency: InvoiceCurrency): string {
    return currency.currentCurrency + ' ' + (+(value / currency.currencyRate)?.toFixed(2)).toLocaleString();
  }

  generatePaymentTermString(paymentTerm: PaymentTerm, invoiceCurrency: InvoiceCurrency, total: number): string{
    let paymentTermString = 'Pay ';

    if (paymentTerm.advance > 0) {
      if (paymentTerm.paymentType === PaymentTermType.PERCENTAGE){
        paymentTermString = paymentTermString + paymentTerm.advance.toFixed(2) + '%';
      }
      else {
        paymentTermString
          = paymentTermString + (+paymentTerm.advance.toFixed(2)) + ' ' + invoiceCurrency.currentCurrency;
      }
      paymentTermString = paymentTermString + ' in advance';
    }

    const balance = paymentTerm.paymentType === PaymentTermType.PERCENTAGE ? 100 - paymentTerm.advance
      : (total - paymentTerm.advance * invoiceCurrency.currencyRate) / invoiceCurrency.currencyRate;

    if (balance > 0) {
      if (paymentTerm.advance > 0) {
        paymentTermString = paymentTermString + ' and';
      }
      paymentTermString = paymentTermString + ' ' + balance.toFixed(2) + (paymentTerm.paymentType === PaymentTermType.PERCENTAGE
        ? '%' : ' ' + invoiceCurrency.currentCurrency);
    }

    if (balance > 0 && paymentTerm.creditDays > 0) {
      paymentTermString = paymentTermString + ' in ' + paymentTerm.creditDays + ' days';
    }

    if (balance > 0) {
      paymentTermString = paymentTermString + ' upon completion';
    }

    return paymentTermString;
  }

  createFuelRateInvoiceFromCommitmentInvoice(ci: Invoice, vcs: VehicleContribution[], name: string, invoiceType: InvoiceType){
    const invoiceLoads: InvoiceLoad[] = [];
    vcs.forEach(vc => {
      const load = ci.loads.find(l => l.loadId === vc.loadId);
      const invoiceLoad = {
        ...load,
        _id: uuid.v4(),
        loadCost: vc.fuelAllowanceAmount == null ? 0 : vc.fuelAllowanceAmount,
        costs: [],
        distance: vc.distance,
        vehicleCategories: [vc.vehicleCategory],
        vehicleIds: [vc.vehicleId],
        vehicleNumbers: [vc.vehicleNumber],
        completedDate: vc.currentDateTime,
        fuelRate: vc.fuelRate,
        fuelEfficiency: vc.fuelEfficiency
      } as InvoiceLoad;
      invoiceLoads.push(invoiceLoad);
    });
    const fuelRateInvoice = {...ci};
    fuelRateInvoice.invoiceName = name;
    fuelRateInvoice.loads = invoiceLoads;
    fuelRateInvoice.type = invoiceType;
    fuelRateInvoice.parentInvoiceRefNo = ci.invoiceRefNo;
    fuelRateInvoice.commitmentDetails = null;
    fuelRateInvoice.loadsCosts = [];
    fuelRateInvoice.additionalCosts = [];
    fuelRateInvoice.taxes = [];
    fuelRateInvoice.discount = null;
    fuelRateInvoice.otherCosts = [];
    fuelRateInvoice.progressStep = ProgressLineStepType.DRAFT;
    fuelRateInvoice.overdueState = OverdueStateType.DEFAULT;
    fuelRateInvoice.approvalState = ApprovalStateType.PENDING_APPROVAL;
    fuelRateInvoice.tab = TabType.DRAFT;
    fuelRateInvoice.total = invoiceLoads.reduce((total, load) => total + load.loadCost, 0);
    return fuelRateInvoice;
  }

  calculateChildInvoicesTotalForCI(invoice: Invoice) {
    const loadsCost = invoice?.loads?.reduce((total, load) => {
      total = total + load.loadCost;
      return total;
    }, 0);
    const additionalCostTotal = invoice.additionalCosts.reduce((total, cost) => {
      total = total + cost.value;
      return total;
    }, 0);
    return loadsCost + additionalCostTotal;
  }

  calculateOtherCostsTotalForCI(invoice: Invoice) {
    const serviceFee = this.calculateChildInvoicesTotalForCI(invoice);
    return invoice.otherCosts.reduce((total, cost) => {
      const costValue = cost.paymentType === PaymentType.PERCENTAGE
        ? (serviceFee / 100 * cost.value)
        : cost.value;
      return total + costValue;
    }, 0);
  }

  calculateAdditionalCostsTotalForCI(additionalCosts: Cost[]) {
    return additionalCosts.reduce((total, cost) => {
      total = total + cost.value;
      return total;
    }, 0);
  }

  calculateDiscountForCI(invoice: Invoice){
    const loadsCost = this.calculateChildInvoicesTotalForCI(invoice);
    const calculatedDiscount =
      (loadsCost / 100) * (invoice?.discount == null ? 0 : invoice?.discount?.value);
    return invoice?.discount == null ? 0
      : (invoice?.discount.paymentType === PaymentType.AMOUNT ? invoice?.discount?.value : calculatedDiscount);
  }

  calculateTotalForCI(invoice: Invoice){ // CI == Child Invoice
    return +this.calculateChildInvoicesTotalForCI(invoice).toFixed(2)
      + +this.calculateOtherCostsTotalForCI(invoice).toFixed(2)
      - +this.calculateDiscountForCI(invoice).toFixed(2);
  }

  calculateOtherAndAdditionalCostTotal(childInvoice: Invoice){
    return this.calculateAdditionalCostsTotalForCI(childInvoice.additionalCosts) + this.calculateOtherCostsTotalForCI(childInvoice);
  }

  calculateServiceFee(paymentSchedule: Invoice) {
    const additionalCostTotal = paymentSchedule?.additionalCosts.reduce((total, cost) => total + cost.value, 0);
    const loadCostTotal = paymentSchedule?.childInvoices
      .reduce((total, invoice) => total + this.calculateTotalForCI(invoice), 0);
    return additionalCostTotal + loadCostTotal;
  }

  calculateLineItemValueForCI(value: number, invoice: Invoice) {
    const serviceFee = this.calculateChildInvoicesTotalForCI(invoice);
    return serviceFee / 100 * value;
  }

  calculateLineItemValue(value: number, invoice: Invoice) {
    const serviceFee = this.calculateServiceFee(invoice);
    return serviceFee / 100 * value;
  }

  calculateDiscount(invoice: Invoice) {
    const serviceFee = this.calculateServiceFee(invoice);
    return serviceFee / 100 * invoice.discount.value;
  }

  calculateSubTotalFR(invoice: Invoice){
    const serviceFee = this.calculateServiceFee(invoice);
    const lineItemsTotal = invoice?.otherCosts
      .reduce((total, cost) => total + (cost.paymentType === PaymentType.PERCENTAGE ? (serviceFee / 100 * cost.value) : cost.value ), 0);
    const serviceFeePlusLineItems = serviceFee + lineItemsTotal;
    const calculatedDiscount =
      (serviceFee / 100) * (invoice?.discount == null ? 0 : invoice?.discount?.value);
    const discount = invoice?.discount == null ? 0
      : (invoice?.discount.paymentType === PaymentType.AMOUNT ? invoice?.discount?.value : calculatedDiscount);
    return +serviceFeePlusLineItems.toFixed(2) - +discount.toFixed(2);
  }

  calculateTax(invoice: Invoice) {
    const totalsAfterTax: number[] = [];
    const taxAmounts: number[] = [];
    const subTotal = this.calculateSubTotalFR(invoice);

    if (invoice?.taxes?.length <= 0) {
      totalsAfterTax[0] = subTotal;
    } else {

      invoice.taxes.forEach((tax, index) => {
        if (index === 0) {
          totalsAfterTax[index]
            = tax.paymentType === PaymentType.AMOUNT ? (tax.value + subTotal) : ((subTotal / 100) * tax.value) + subTotal;
          taxAmounts[index] = tax.paymentType === PaymentType.AMOUNT ? tax.value : ((subTotal / 100) * tax.value);
        } else {
          totalsAfterTax[index] = tax.paymentType === PaymentType.AMOUNT ? (tax.value + totalsAfterTax[index - 1])
            : ((totalsAfterTax[index - 1] / 100) * tax.value) + totalsAfterTax[index - 1];
          taxAmounts[index] = tax.paymentType === PaymentType.AMOUNT ? tax.value : ((totalsAfterTax[index - 1] / 100) * tax.value);
        }
      });
    }
    return [totalsAfterTax, taxAmounts];
  }

  onSubmitForApproval(invoice: Invoice) {
    const updatedInvoice = {
      ...invoice, progressStep: ProgressLineStepType.SENT_FOR_INTERNAL_APPROVAL,
      approvalState: ApprovalStateType.SEND_FOR_INTERNAL_APPROVAL, stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(updatedInvoice));
  }

  onInternalReject(invoice: Invoice) {
    const updatedInvoice = {
      ...invoice, progressStep: ProgressLineStepType.SENT_FOR_INTERNAL_APPROVAL,
      approvalState: ApprovalStateType.INTERNALLY_REJECTED, stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(updatedInvoice));
  }

  onInternalApprove(invoice: Invoice) {
    const updatedInvoice = {
      ...invoice, progressStep: ProgressLineStepType.INTERNAL_APPROVED,
      approvalState: ApprovalStateType.INTERNALLY_APPROVED, stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(updatedInvoice));
  }

  sentToUnpaid(invoice: Invoice) {
    const updatedInvoice = {
      ...invoice, progressStep: ProgressLineStepType.CONFIRM, approvalState: ApprovalStateType.CONFIRM,
      tab: TabType.UNPAID, stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(updatedInvoice));
  }

  markAsOverdue(invoice: Invoice) {
    const updatedInvoice = {...invoice, overdueState: OverdueStateType.OVERDUE,
      tab: TabType.OVERDUE, stateUpdatedAt: new Date()};
    this.store$.dispatch(new UpdateInvoiceAction(updatedInvoice));
  }

  markAsPaid(invoice: Invoice) {
    const updatedInvoice = {
      ...invoice, progressStep: ProgressLineStepType.PAID, tab: TabType.PAID,
      approvalState: ApprovalStateType.PAID, stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(updatedInvoice));
  }

  selectOne(event: MatCheckboxChange, vc: VehicleContribution,
            selectedVehicleContributions: VehicleContribution[],
            invoice: Invoice): {
    allSelected: boolean;
    selectedVCs: VehicleContribution[];
  } {
    let allSelected = false;
    if (event.checked) {
      const allVcs = invoice.commitmentDetails.blocks.flatMap(block => {
        if (block.isFuelAllowance){
          return block.vehicleContributions;
        }
      });
      const preSelectedVcs = invoice.commitmentDetails.blocks.flatMap(block => {
        if (block.isFuelAllowance) {
          return block.vehicleContributions.filter(v => v.invoiced);
        }
      });
      selectedVehicleContributions.push(vc);
      if (preSelectedVcs.length + selectedVehicleContributions.length === allVcs.length){
        allSelected = true;
      }
    } else {
      const index = selectedVehicleContributions.findIndex(
        vehicleContribution => vehicleContribution === vc
      );
      if (index !== -1) {
        selectedVehicleContributions.splice(index, 1);
      }
    }
    return {
      allSelected,
      selectedVCs: selectedVehicleContributions
    };
  }

  selectAll(event: MatCheckboxChange, commitmentBlocks: CommitmentBlock[], invoice: Invoice): {
    selectedVCs: VehicleContribution[];
    updatedInvoice: Invoice;
    allSelected: boolean;
  } {
    const allSelected = event.checked;
    const mutableBlocks = invoice.commitmentDetails.blocks.map(block => ({
      ...block,
      vehicleContributions: block.vehicleContributions.map(load => ({ ...load }))
    }));

    mutableBlocks.forEach(block => {
      block.vehicleContributions.forEach(load => {
        if (!load.invoiced) {
          load.selected = event.checked;
        }
      });
    });

    const selectedVCs = allSelected
      ? commitmentBlocks.flatMap(block =>
        block.isFuelAllowance
          ? block.vehicleContributions.filter(vc => !vc.invoiced)
          : []
      )
      : [];

    const updatedInvoice = {
      ...invoice,
      commitmentDetails: {
        ...invoice.commitmentDetails,
        blocks: mutableBlocks
      }
    };

    return {
      selectedVCs,
      updatedInvoice,
      allSelected
    };
  }
}
