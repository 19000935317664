import { OptimizationInfo } from './../../../wizard/models/optimization-info.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { EntityMap } from '@shared/models/types';
import { DropDownValue } from 'app/modules/dynamic-form/models/drop-down-value';
import { Property } from 'app/modules/dynamic-form/models/property.model';
import { AddExternalVehicleCategory } from 'app/modules/wizard/components/add-external-vehicle-length-category/add-external-vehicle-length-category.component';
import { first, tap } from 'rxjs/operators';
import { VehicleCountModifierComponent } from '../../../wizard/components/vehicle-count-modifier/vehicle-count-modifier.component';
import { DynamicFormService } from '../../services/dynamic-form.service';
import { Actions, Select, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { setOptimizationInfo } from 'app/modules/wizard/store/wizard.actions';
import { WizardState } from 'app/modules/wizard/store/wizard.state';
import { Observable } from 'rxjs';
export interface VehicleCategory {
  vehicleCategory: string;
  vehiclesData: VehicleData[];
}

export interface VehiclesWithOrgType {
  mainOrgVehicles: VehicleCategory[];
  orgPatrnerVehicles: VehicleCategory[];
}
export interface VehicleData {
  maxLoadVolume: number;
  maxLoadWeight: number;
  dimensions: {
    length: number;
    width: number;
    height: number;
  };
  vehicleNumber: string | number;
  costPerKM: number;
}

@Component({
  selector: 'hmt-vehicle-capacity',
  templateUrl: './vehicle-capacity.component.html',
  styleUrls: ['./vehicle-capacity.component.scss'],
})
export class VehicleCapacityComponent implements OnInit {
  @Input() internalVehicleCategories: EntityMap<
    number,
    { count: number; vehicleData: VehicleData[] }
  > = {};
  @Input() externalVehicleCategories: EntityMap<
    number,
    { count: number; vehicleData: VehicleData[] }
  > = {};

  @Select(WizardState.getJobFile) jobFile$: Observable<any>;

  private unsubscribe: Subject<void> = new Subject();
  @Select(WizardState.getOptimizationInfo)
  optimizationInfo$: Observable<OptimizationInfo>;
  config: Property;
  group: FormGroup;
  values: DropDownValue[] = [];
  paramsMap = {}; // TODO: implement a type
  queryParamsMap = {};
  dataMap: any;
  jobFileData: any = {};
  availableInternalVehicleCategories: EntityMap<
    number,
    { count: number; vehicleData: VehicleData[] }
  > = {};
  availableExternalVehicleCategories: EntityMap<
    number,
    { count: number; vehicleData: VehicleData[] }
  > = {};

  vehicleCategoryListInternal: EntityMap<
    number,
    { count: number; vehicleData: VehicleData[] }
  > = {};

  vehicleCategoryListExternal: EntityMap<
    number,
    { count: number; vehicleData: VehicleData[] }
  > = {};

  constructor(
    private readonly dialogHandlerService: DialogHandlerService,
    private dynamicFormService: DynamicFormService,
    public store$: Store,
    public actions$: Actions
  ) {}

  ngOnInit(): void {
    this.addFormControls();
    this.jobFile$.subscribe((data) => {
      if (data && data.operationId) {
        this.loadAvailableVehicles(data.operationId);
      } else {
        this.loadInitialData();
      }
    });
  }

  addFormControls(): void {
    this.group.addControl('useSelectedVehiclesForAll', new FormControl(false));
    this.group.addControl('useVehicleCosts', new FormControl(true));
    this.group.addControl('vehicleCapacities', new FormGroup({
      internal: new FormGroup({}),
      external: new FormGroup({}),
    }));
  }

  private loadInitialData(): void {
    this.optimizationInfo$.subscribe((info) => {
      if (info !== null) {
        this.internalVehicleCategories = {
          ...info['internalVehicleCategories'],
        };
        this.externalVehicleCategories = {
          ...info['externalVehicleCategories'],
        };
        this.vehicleCategoryListInternal = {
          ...info['vehicleCategoryListInternal'],
        };
        this.vehicleCategoryListExternal = {
          ...info['vehicleCategoryListExternal'],
        };
        this.generateVehicleCategoryFormControls(
          this.internalVehicleCategories
        );
        this.processAlreadySavedData();
      } else {
        this.loadAvailableVehicles();
      }
    });
  }

  public processAlreadySavedData(): void {
    if (!this.jobFileData['vehicleCapacities']) {
      return;
    }

    const internal = [];
    const external = [];

    const vehicleCapacities = this.jobFileData['vehicleCapacities'];
    const useSelectedVehiclesForAll: boolean =
      this.jobFileData['useSelectedVehiclesForAll'] ?? false;
    const useVehicleCosts: boolean =
      this.jobFileData['useVehicleCosts'] ?? true;
    this.availableInternalVehicleCategories = Object.assign(
      {},
      this.internalVehicleCategories
    );

    if (vehicleCapacities.internal) {
      Object.keys(vehicleCapacities.internal).forEach((k) => {
        internal.push({ [k]: vehicleCapacities.internal[k] });
        // assign original categories to a new variable because data of original result is needed to fulfill a corner case
        this.internalVehicleCategories[k] = {
          count: vehicleCapacities.internal[k]?.count ?? 0,
          vehicleData: vehicleCapacities.internal[k]?.vehiclesData ?? [],
        };
      });
    }

    if (vehicleCapacities.external) {
      Object.keys(vehicleCapacities.external).forEach((k) => {
        external.push({ [k]: vehicleCapacities.external[k] });
        this.availableExternalVehicleCategories = JSON.parse(
          JSON.stringify(this.externalVehicleCategories)
        );
        this.externalVehicleCategories[k] = {
          count: vehicleCapacities.external[k]?.count ?? 0,
          vehicleData: vehicleCapacities.external[k]?.vehiclesData ?? [],
        };
      });
    }

    this.generateVehicleCategoryFormControls(this.internalVehicleCategories);

    this.group.get('vehicleCapacities').patchValue({
      internal,
      external,
    });
    this.group
      .get('useSelectedVehiclesForAll')
      .patchValue(useSelectedVehiclesForAll);
    this.group.get('useVehicleCosts').patchValue(useVehicleCosts);
  }

  public loadAvailableVehicles(id?: string): void {
    this.dynamicFormService
      .fetchValuesForDropdown(
        this.config.dataSourceUrl,
        this.config.dataSourceUrlSuffix,
        false,
        {},
        { operationId: id ? id : this.jobFileData.operationId }
      )
      .subscribe(
        (data: any) => {
          data.mainOrgVehicles.forEach((vcld) => {
            this.internalVehicleCategories[vcld.vehicleCategory] = {
              count: vcld.vehiclesData.length,
              vehicleData: vcld.vehiclesData,
            };
            this.vehicleCategoryListInternal[vcld.vehicleCategory] = {
              count: vcld.vehiclesData.length,
              vehicleData: vcld.vehiclesData,
            };
          });

          data.orgPatrnerVehicles.forEach((vcld) => {
            this.externalVehicleCategories[vcld.vehicleCategory] = {
              count: vcld.vehiclesData.length,
              vehicleData: vcld.vehiclesData,
            };
            this.vehicleCategoryListExternal[vcld.vehicleCategory] = {
              count: vcld.vehiclesData.length,
              vehicleData: vcld.vehiclesData,
            };
          });
          this.store$.dispatch(
            new setOptimizationInfo({
              internalVehicleCategories: this.internalVehicleCategories,
              externalVehicleCategories: this.externalVehicleCategories,
              vehicleCategoryListInternal: this.vehicleCategoryListInternal,
              vehicleCategoryListExternal: this.vehicleCategoryListExternal,
            })
          );
          this.generateVehicleCategoryFormControls(
            this.internalVehicleCategories
          );
          this.processAlreadySavedData();
        },
        (err) => {
          console.log('Error loading vehicle length categories: ', err);
        }
      );
  }

  editVehicleCategory(
    vehicleType: string,
    vehicleCount: number,
    vehicleCategory: number
  ) {
    const data = { count: vehicleCount };
    const dialogRef = this.dialogHandlerService.openDialog(
      VehicleCountModifierComponent,
      data,
      {
        height: '25%',
        width: '25%',
        position: {
          top: '10%',
          left: '37.5%',
        },
      }
    );

    dialogRef.componentInstance.modifiedCount
      .pipe(
        first(),
        tap((value: number) => {
          const categories = vehicleType === 'INTERNAL' ? this.internalVehicleCategories : this.externalVehicleCategories;
          const originalCategories = vehicleType === 'INTERNAL' ? this.vehicleCategoryListInternal : this.vehicleCategoryListExternal;
          const oldCount = categories[vehicleCategory].count;
          this.generateVehicleData(
            categories,
            vehicleCategory,
            value,
            originalCategories
          );
            this.patchFormData(
              categories[vehicleCategory],
              vehicleType,
              vehicleCategory
            );
            this.jobFileData['vehicleCapacities'][vehicleType.toLowerCase()][vehicleCategory] = {
              count: categories[vehicleCategory].count,
              vehiclesData: categories[vehicleCategory].vehicleData,
            };
            this.store$.dispatch(
              new setOptimizationInfo({
                internalVehicleCategories: this.internalVehicleCategories,
                externalVehicleCategories: this.externalVehicleCategories,
                vehicleCategoryListInternal: this.vehicleCategoryListInternal,
                vehicleCategoryListExternal: this.vehicleCategoryListExternal,
              })
            );
        })
      )
      .subscribe();
  }

  private generateVehicleData(
    categories: EntityMap<
      number,
      { count: number; vehicleData: VehicleData[] }
    >,
    vehicleCategory: number,
    modifiedCount: number,
    originalCategory: EntityMap<
      number,
      { count: number; vehicleData: VehicleData[] }
    >
  ) {
    let vehicleData = [...categories[vehicleCategory].vehicleData];
    const currentCount = vehicleData.length;
    const countDiff = modifiedCount - currentCount;

    if (countDiff > 0) {
      const availableVehicles = originalCategory[vehicleCategory]?.vehicleData || [];
      const unusedVehicles = availableVehicles.filter(v => !vehicleData.some(vd => vd.vehicleNumber === v.vehicleNumber));
      for (let i = 0; i < countDiff && i < unusedVehicles.length; i++) {
        vehicleData.push({ ...unusedVehicles[i] });
      }
      if (vehicleData.length < modifiedCount && availableVehicles.length > 0) {
        const lastAvailableVehicle = availableVehicles[availableVehicles.length - 1];
        while (vehicleData.length < modifiedCount) {
          vehicleData.push({ ...lastAvailableVehicle });
        }
      }
    } else if (countDiff < 0) {
      vehicleData = vehicleData.slice(0, modifiedCount);
    }
    categories[vehicleCategory] = {
      ...categories[vehicleCategory],
      count: vehicleData.length, // Use the actual length of vehicleData
      vehicleData: vehicleData,
    };
  }

  private generateVehicleCategoryFormControls(categories): void {
    if (!this.group) {
      return;
    }

    const vehicleCapacities = this.group.get('vehicleCapacities') as FormGroup;
    if (vehicleCapacities) {
      this.group.removeControl('vehicleCapacities');
    }

    const internalGroup: FormGroup = new FormGroup({});
    Object.keys(this.internalVehicleCategories).forEach((VehicleCategory) => {
      internalGroup.addControl(
        VehicleCategory,
        new FormGroup({
          count: new FormControl(
            this.internalVehicleCategories[VehicleCategory].count
          ),
          vehicleCategory: new FormControl(+VehicleCategory),
          vehiclesData: new FormArray(
            this.internalVehicleCategories[VehicleCategory].vehicleData.map(
              (vd: VehicleData) => {
                return new FormGroup({
                  maxLoadVolume: new FormControl(vd?.maxLoadVolume),
                  maxLoadWeight: new FormControl(vd?.maxLoadWeight),
                  dimensions: new FormGroup({
                    length: new FormControl(vd?.dimensions?.length),
                    width: new FormControl(vd?.dimensions?.width),
                    height: new FormControl(vd?.dimensions?.height),
                  }),
                  vehicleNumber: new FormControl(vd?.vehicleNumber || 0),
                  costPerKM: new FormControl(vd?.costPerKM || 0),
                });
              }
            )
          ),
        })
      );
    });
    this.group.addControl(
      'vehicleCapacities',
      new FormGroup({
        internal: internalGroup,
        external: new FormGroup({}),
      })
    );
  }

  private patchFormData(
    category: { count: number; vehicleData: VehicleData[] },
    vehicleType: string,
    VehicleCategoryType: number
  ): void {
    if (!vehicleType) {
      return;
    }
    category.vehicleData = category.vehicleData.slice(0, category.count);

    const controller = (
      this.group
        .get('vehicleCapacities')
        .get(vehicleType.toLowerCase()) as FormGroup
    ).controls['' + VehicleCategoryType];

    controller.patchValue({
      count: category.count,
      vehicleCategory: +VehicleCategoryType,
    });
    const vehiclesDataFormArray: FormArray = (
      this.group
        .get('vehicleCapacities')
        .get(vehicleType.toLowerCase()) as FormGroup
    ).controls['' + VehicleCategoryType].get('vehiclesData') as FormArray;

    vehiclesDataFormArray.clear();
    category.vehicleData
      .filter((category) => category)
      .map((vd: VehicleData) => {
        return new FormGroup({
          maxLoadVolume: new FormControl(vd?.maxLoadVolume || 0),
          maxLoadWeight: new FormControl(vd?.maxLoadWeight || 0),
          dimensions: new FormGroup({
            length: new FormControl(vd?.dimensions?.length || 0),
            width: new FormControl(vd?.dimensions?.width || 0),
            height: new FormControl(vd?.dimensions?.height || 0),
          }),
          vehicleNumber: new FormControl(vd?.vehicleNumber || 0),
          costPerKM: new FormControl(vd?.costPerKM || 0),
        });
      })
      .forEach((cntrl) => vehiclesDataFormArray.push(cntrl));
  }

  setExternalType(type: number): void {
    const VehicleData: VehicleData[] =
      this.internalVehicleCategories[type]?.vehicleData ?? [];
    if (!this.externalVehicleCategories[type]) {
      this.externalVehicleCategories[type] = {
        count: 1,
        vehicleData: [
          {
            // TODO: fix by finding another solution
            // take volume of the last vehicle of the capacity of internal vehicles matching the category.
            maxLoadVolume: VehicleData
              ? VehicleData[VehicleData.length - 1].maxLoadVolume
              : 0,
            maxLoadWeight: VehicleData
              ? VehicleData[VehicleData.length - 1].maxLoadWeight
              : 0,
            dimensions: {
              length: VehicleData
                ? VehicleData[VehicleData.length - 1].dimensions.length
                : 0,
              width: VehicleData
                ? VehicleData[VehicleData.length - 1].dimensions.width
                : 0,
              height: VehicleData
                ? VehicleData[VehicleData.length - 1].dimensions.height
                : 0,
            },
            vehicleNumber: VehicleData
              ? VehicleData[VehicleData.length - 1].vehicleNumber
              : 0,
            costPerKM: VehicleData
              ? VehicleData[VehicleData.length - 1].costPerKM
              : 0,
          },
        ],
      };
    }
    this.addExternalCapacities(type);
  }

  addExternalCapacities(VehicleCategory) {
    const externalGroup: FormGroup = new FormGroup({});
    externalGroup.addControl(
      VehicleCategory,
      new FormGroup({
        count: new FormControl(
          this.externalVehicleCategories[VehicleCategory].count
        ),
        vehicleCategory: new FormControl(+VehicleCategory),
        vehiclesData: new FormArray(
          this.externalVehicleCategories[VehicleCategory].vehicleData.map(
            (vd: VehicleData) => {
              return new FormGroup({
                maxLoadVolume: new FormControl(vd.maxLoadVolume),
                maxLoadWeight: new FormControl(vd.maxLoadWeight),
              });
            }
          )
        ),
      })
    );
    const externalGp = this.group
      .get('vehicleCapacities')
      .get('external') as FormGroup;
    externalGp.addControl(
      VehicleCategory,
      new FormGroup({
        count: new FormControl(
          this.externalVehicleCategories[VehicleCategory].count
        ),
        vehicleCategory: new FormControl(+VehicleCategory),
        vehiclesData: new FormArray(
          this.externalVehicleCategories[VehicleCategory].vehicleData.map(
            (vd: VehicleData) => {
              return new FormGroup({
                maxLoadVolume: new FormControl(vd.maxLoadVolume),
                maxLoadWeight: new FormControl(vd.maxLoadWeight),
              });
            }
          )
        ),
      })
    );
  }
}
