import {PrincipalContact} from '@shared/models/principalContact.model';
import {LocationType} from '@shared/models';
import {GatewayLocation} from '@shared/models/gateway-location.model';
import {ServiceWorkOrderInfo} from '../models/service-work-order.info';
import {ServiceViewModel} from '../models/service-view.model';
import {CustomsChannel} from '../models/enums/customs-channel.enum';
import {ContainerSelectionMethod} from '../models/enums/container-selection-method.enum';
import {PackageType} from '../models/enums/package-type.enum';
import {CommodityType} from '../models/enums/commodity-type';
import {InitContainerViewModel} from '../models/init-container-view.model';
import {DateMap} from '@core/models/job-file';
import {Address} from '@shared/models/address.model';
import {ContactPerson} from '../models/contact-person.model';
import {OrgInfo} from '../../contract/models/orgInfo.model';
import {JobSummary} from '../models/job-summary.model';
import {ShipmentType} from '../models/enums/shipment-type.enum';

export function getJobSummary() {
  return {
    containers: getInitContainers(2),
    customer: null,
    customerHouseAgency: undefined,
    customerHouseAgent: null,
    customsChannel: undefined,
    freightDetailMap: undefined,
    generalDetailMap: undefined,
    keyDates: getDateMap(),
    noOfContainer: 0,
    orgId: '',
    organization: null,
    shipper: undefined,
    workflowId: 'OF-EX-LCL-ET-ECYP',
    keyValue: getKeyValue(),
    jobRefId: 'FCL-12345883',
    jobTitle: 'Nike - Batch 2334 - RM - Export',
    generatedDate: {
      date: new Date(),
      order: 1,
      title: 'test 01'
    },
    jobType: 'Work Assignment',
    shipmentType: ShipmentType.BOI,
    recipientDetails:[],
    vehicleCategory: '',
    vehicleCategoryId: '',
    vehicleCategoryDistId: ''
  } as JobSummary;
}

export function getKeyValue() {
  return {
    bookingNumber: '148959584',
    countryOfOrigin: 'Sri Lanka',
    loadingTerminal: 'South Asia Gateway Terminal (Pvt) Ltd.',
    numberOfContainers: 3,
    portOfLoading: 'Port of Colombo',
    shippingLine: 'Maersk',
    vessel: '541',
    voyageNumber: '485825448'
  };
}

export function getOrgInfo(): OrgInfo {
  return {
    principleContactEmail: [],
    principleContactFax: '',
    address: '',
    companyRegNum: '',
    contactNumbers: [],
    customerReferenceNo: '',
    name: '',
    principleContactDesignation: '',
    principleContactMobile: '',
    principleContactName: '',
    tinNum: '',
    vatNum: '',
    logoUrl: '',
    tickerCode: ''
  };
}

export function getPrincipalContactPerson(): ContactPerson {
  return {
    address: getAddress(),
    designation: 'Manager',
    email: 'email@gmail.com',
    ext: 'ext',
    fax: '1245879633',
    linkedIn: '',
    mobile: '+941245896',
    name: 'Amal Perera',
    telephone: '+94588559',
    title: 'principal contact'
  };
}

export function getAddress(): Address {
  return {
    addressLine: 'No: 231, D. M. Colombage Mawatha',
    city: 'Sri Jayawardenepura Kotte',
    country: undefined,
    state: 'Sri lanka',
    zipCode: '00600'
  };
}


export function getDateMap(): DateMap[] {
  return [
    {
      title: 'Last Date of Shipment',
      order: 0,
      date: new Date()
    },
    {
      title: 'Last Date of Shipment',
      order: 0,
      date: new Date()
    },
    {
      title: 'Cargo Opening Date and Time',
      order: 0,
      date: new Date()
    },
    {
      title: 'Planned Production Completion Date and Time',
      order: 0,
      date: new Date()
    },
    {
      title: 'Cargo Cutoff Date and Time',
      order: 0,
      date: new Date()
    },
    {
      title: 'Planned Cargo Ready Date and Time',
      order: 0,
      date: new Date()
    },
  ];
}

export function getInitContainers(numberOfContainers = 2): InitContainerViewModel[] {
  return [...Array(numberOfContainers).keys()].map(
    (val: number, index: number) =>
      ({
        containerId: `containerId-${index}`,
        containerTypeDescription: '40 ft. Dry Container',
        payloadGrossWeight: 100 * (val + 1),
        gpsEncodedString: 'wn~h@q~rfNs@jAH@xCR^CNAAPCb@A`BElBSCm@I}Be@_Cc@_AMq@Ai@Fo@Vy@j@o@j@eAhASXw@|Aq@fBUl@]~Aa@|CBPEh@I\MZc@^a@D_BAkDQQI]IaBM{BMaDE_BDGj@_@zA{@dBu@dBEZ?LJb@R`@lApBNl@?p@Mb@S`@eCtC}ApBgAfCk@`BMZUVc@ZgCnAuAdAs@~@sChEoAtBoAxBK\@r@Fl@TjABj@E^INm@fAm@r@_@j@KZG\Al@@h@C~@RzDRfAHXZj@BRLZZtADb@AfAG~AERW`A_@~@hAl@dAh@RTLHPJ^d@Nd@RnACfAA|A?b@Bp@V|@@H?FAD`@dA~@jBTf@FNJb@Nz@FxADL@ZHrAHl@_AP_@LWPqCzCg@d@w@`@k@T_@JM?a@J{@P{AT}@Rs@Tq@Xu@j@m@h@mAt@oB|@w@`@o@d@[Ze@\]PWHo@BEFA@G@G?KGAAq@GMCIEAEgDsBWCWBQJi@l@i@j@q@XqJvBwFlA_Cj@gB`@_@@k@@cDSyBMgAoA}EtB_E|A}Br@wAf@mAd@oAd@cCfA_EzAgA^?n@GrCA`CEtIClBGdACf@@@BREHGFQBOCUB]@kAPKHKLGN@JHT?LPp@\dBd@hCHb@w@PqK|BgFjA}D~@yKnC}IpBg@L?BABADEFQBICAAe@JyI|BcF|AcAVeB`@oEbAmFpAQBKFULq@f@m@n@k@p@eAx@sAf@wEdAcCh@mBh@SPI@_ARqATiDjAkA\{Jt@mAFa@Ce@IcHqB}Ak@KTGTAHk@v@wA~Ao@r@oAp@aB|@y@t@mA~Aw@nAUJU@w@[_@Es@@@]Fk@zB_LXy@Xk@Zk@NYFUNKHARB|GoDj@[NQTe@HM',
        commodity: CommodityType.DANGEROUS,
        packageType: PackageType.BOXES,
        containerSelectionMethod: ContainerSelectionMethod.DRIVER,
        contaierType: '40FT',
        order: 0,
        containerNumber: '',
        containerSealNumber: '',
        payloadNetWeight: 80,
        services: getServices(5),
        payloadWeightInKg: 3400,
        customsChannel: CustomsChannel.GREEN,
        hsCodes: ['272121101', '102332111'],
        otherRefNumbers: [],
        orderRefNumbers: []
      })
  );
}

export function getServices(count: number): ServiceViewModel[] {
  return [...Array(count).keys()].map(
    (val: number, index: number) =>
      ({
        serviceName: `Empty container pickup`,
        activityId: `EMPTY-CONTAINER-PICKUP`,
        estimatedDuration: 75.0 + index,
        workOrders: getWorkOrders(3),
        // requestedTimeOfLeave: new Date(),
        // requestedTimeOfArrival: new Date(),
        positioningTime: new Date(),
        locationDetails: getGatewayLocation(),
        // provider: undefined
      } as ServiceViewModel)
  );
}

export function getWorkOrders(count: number): ServiceWorkOrderInfo[] {
  return [...Array(count).keys()].map(
    (val: number, index: number) =>
      ({
        workOrderId: `workOrderId`,
        workOrderName: `Complete Cargo Loading`,
        durationInMinutes: 105,
        required: true,
        optional: false,
      } as ServiceWorkOrderInfo)
  );
}

export function getGatewayLocation(): GatewayLocation {
  return {
    contact: undefined,
    geoFence: [],
    geoLocation: undefined,
    id: '',
    address: getAddress(),
    faxNumber: '',
    locationType: LocationType.CONTAINER_YARD,
    name: '',
    phoneNumber: '',
    principalContact: getPrincipalContact(),
    bayInformation: undefined
  };
}


export function getPrincipalContact(): PrincipalContact {
  return {
    designation: 'Manager',
    email: 'email@gmail.com',
    firstName: 'Saman',
    gender: 'male',
    lastName: 'Kumara',
    mobile: '+94771144558822',
  } as PrincipalContact;
}

export function getJobSummaryConfig() {
  return {
    config: [
      {
        componentName: 'jobDetails',
        dataMappingPaths: [
          {
            path: 'jobTitle',
            mapTo: 'jobTitle'
          },
          {
            path: 'jobRefId',
            mapTo: 'jobRefId'
          },
          {
            path: 'customer',
            mapTo: 'customer'
          },
          {
            path: 'generatedDate',
            mapTo: 'generatedDate'
          },
          {
            path: 'shipmentType',
            mapTo: 'shipmentType'
          },
          {
            path: 'jobType',
            mapTo: 'jobType'
          },
          {
            path: 'workflowId',
            mapTo: 'workflowId'
          }
        ]
      },
      {
        componentName: 'orgInfo',
        dataMappingPaths: [
          {
            path: 'customer',
            mapTo: 'orgInfo'
          },
          {
            defaultValue: 'Customer Details',
            mapTo: 'title'
          }
        ]
      },
      {
        componentName: 'orgInfo',
        dataMappingPaths: [
          {
            path: 'shipper',
            mapTo: 'orgInfo'
          },
          {
            defaultValue: 'Shipper Details',
            mapTo: 'title'
          }
        ]
      },
      {
        componentName: 'orgInfo',
        dataMappingPaths: [
          {
            path: 'customsHouseAgency',
            mapTo: 'orgInfo'
          },
          {
            defaultValue: 'Customs House Agency Details',
            mapTo: 'title'
          }
        ]
      },
      {
        componentName: 'freightDetails',
        dataMappingPaths: [
          {
            path: 'freightDetailMap',
            mapTo: 'freightDetails'
          },
          {
            defaultValue: 'Freight Details',
            mapTo: 'title'
          }
        ]
      },
      {
        componentName: 'freightDetails',
        dataMappingPaths: [
          {
            path: 'freightDetailMap',
            mapTo: 'freightDetails'
          },
          {
            defaultValue: 'General Details',
            mapTo: 'title'
          }
        ]
      },
      {
        componentName: 'transportPlan',
        dataMappingPaths: [
          {
            path: 'containers',
            mapTo: 'containers'
          },
          {
            path: 'jobRefId',
            mapTo: 'jobRefId'
          }
        ]
      },
      {
        componentName: 'informationTable',
        dataMappingPaths: [
          {
            path: 'containers',
            mapTo: 'containers'
          },
          {
            defaultValue: {
              columnNames: [
                'containerId',
                'payloadGrossWeight',
                'payloadNetWeight'
              ],
              tableHeaders: [
                'Container No',
                'Gross Weight',
                'Net Weight'
              ]
            },
            mapTo: 'title'
          }
        ]
      }
    ],
    workflowId: 'OF-EX-FCL-ET-ECYP'
  };
}

